import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdfb8daa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container pl-0 pr-0" }
const _hoisted_2 = { class: "row justify-content-between" }
const _hoisted_3 = { class: "col-8 align-self-center" }
const _hoisted_4 = { class: "font-size-lg pt-1" }
const _hoisted_5 = { class: "wc-close-width col-md-auto" }
const _hoisted_6 = { class: "container font-weight-bold font-size-xl pl-0 pr-0" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-auto text-center" }
const _hoisted_9 = { class: "col-sm-1 pt-4 mt-3" }
const _hoisted_10 = { class: "col-md-auto text-center" }
const _hoisted_11 = { class: "col-2 pt-4 mt-3 font-size-base" }
const _hoisted_12 = { class: "pt-1" }
const _hoisted_13 = { class: "font-weight-normal font-size-lg pt-2" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-3 offset-6 font-weight-bold text-right" }
const _hoisted_16 = {
  class: "col-3 text-center",
  "data-testid": "totalSelectedWeight"
}
const _hoisted_17 = { class: "row text-center" }
const _hoisted_18 = {
  class: "col-3 offset-9",
  "data-testid": "totalSelectedPrice"
}
const _hoisted_19 = { class: "container" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-7 align-self-center" }
const _hoisted_22 = { class: "col-5" }
const _hoisted_23 = ["aria-label"]
const _hoisted_24 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCProductPricing = _resolveComponent("WCProductPricing")!
  const _component_WCModalHeader = _resolveComponent("WCModalHeader")!
  const _component_WCSimpleReactiveQuantityAdjustor = _resolveComponent("WCSimpleReactiveQuantityAdjustor")!
  const _component_WCModalBody = _resolveComponent("WCModalBody")!
  const _component_WCCurrentOrderTypeBadge = _resolveComponent("WCCurrentOrderTypeBadge")!
  const _component_WCModalFooter = _resolveComponent("WCModalFooter")!
  const _component_WCModal = _resolveComponent("WCModal")!

  return (_openBlock(), _createBlock(_component_WCModal, {
    position: "center",
    onDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.DISMISS)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_WCModalHeader, { class: "align-items-baseline" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('customizeItem', { itemName: _ctx.item.name })), 1)
              ]),
              _createTextVNode(),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.CANCEL))),
                  class: "btn btn-link text-muted underline-link-from-center"
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cancel')) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    icon: "xmark",
                    size: "lg",
                    class: "ml-2"
                  })
                ])
              ])
            ]),
            _createTextVNode(),
            _createVNode(_component_WCProductPricing, {
              class: "text-left font-size-base font-weight-bold wc-price-width",
              item: _ctx.item
            }, null, 8, ["item"])
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalBody, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.$t('quantity')) + " ", 1),
                  _createVNode(_component_WCSimpleReactiveQuantityAdjustor, {
                    class: "border border-primary mb-3 wc-quantity-width rounded mt-2",
                    modelValue: _ctx.randomWeightItemWeightedQuantity,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.randomWeightItemWeightedQuantity) = $event)),
                    displayValue: _ctx.displayRandomWeightedQuantity,
                    initialValue: _ctx.minimumRandomWeightedQuantity,
                    hasRemove: false,
                    incrementStep: _ctx.randomWeightedQuantityIncrement,
                    maxQty: _ctx.noMax,
                    minQty: _ctx.minimumRandomWeightedQuantity,
                    isNextIncrementValid: _ctx.isNextRandomWeightedQuantityIncrementValid,
                    isNextDecrementValid: _ctx.isNextRandomWeightedQuantityDecrementValid,
                    "data-testid": "quantityAdjuster"
                  }, null, 8, ["modelValue", "displayValue", "initialValue", "incrementStep", "maxQty", "minQty", "isNextIncrementValid", "isNextDecrementValid"])
                ]),
                _createTextVNode(),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "xmark",
                    size: "sm"
                  })
                ]),
                _createTextVNode(),
                _createElementVNode("div", _hoisted_10, [
                  _createTextVNode(_toDisplayString(_ctx.$t('weight')) + " ", 1),
                  _createVNode(_component_WCSimpleReactiveQuantityAdjustor, {
                    class: "border border-primary mb-3 wc-quantity-width rounded mt-2",
                    modelValue: _ctx.randomWeightItemQuantity,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.randomWeightItemQuantity) = $event)),
                    displayValue: _ctx.displayItemQuantity,
                    initialValue: _ctx.minimumItemQuantity,
                    hasRemove: false,
                    incrementStep: _ctx.randomItemIncrement,
                    maxQty: _ctx.noMax,
                    minQty: _ctx.minimumItemQuantity,
                    isNextIncrementValid: _ctx.isNextItemQuantityIncrementValid,
                    isNextDecrementValid: _ctx.isNextItemQuantityDecrementValid,
                    "data-testid": "weightAdjuster"
                  }, null, 8, ["modelValue", "displayValue", "initialValue", "incrementStep", "maxQty", "minQty", "isNextIncrementValid", "isNextDecrementValid"])
                ]),
                _createTextVNode(),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('eachForwardSlash')), 1)
                ])
              ]),
              _createTextVNode(),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('total')) + " " + _toDisplayString(': '), 1),
                  _createTextVNode(),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.totalWeight) + " " + _toDisplayString(_ctx.weightUnit), 1)
                ]),
                _createTextVNode(),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$filters.currency(_ctx.extendedPrice)), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalFooter, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                (_ctx.orderType)
                  ? (_openBlock(), _createBlock(_component_WCCurrentOrderTypeBadge, {
                      key: 0,
                      orderType: _ctx.orderType,
                      "data-testid": "orderTypeBadge"
                    }, null, 8, ["orderType"]))
                  : _createCommentVNode("", true)
              ]),
              _createTextVNode(),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addToCartAndClose())),
                  class: "btn btn-primary btn-block d-flex justify-content-center align-items-center",
                  "aria-label": _ctx.$t('addToCart'),
                  "data-testid": "addToCart"
                }, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('addToCart')), 1),
                  _createTextVNode(),
                  _createVNode(_component_font_awesome_icon, {
                    icon: "cart-shopping",
                    size: "sm"
                  })
                ], 8, _hoisted_23)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}